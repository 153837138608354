import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import "../index.css"; // Ensure your CSS is imported

const AboutUs = () => {
  return (
    <Container>
      <Card className="my-3">
        <Card.Body>
          <h2 className="about-title">About Joni Clare 🌱 🧘‍♀️ 🎼</h2>
          <p className="about-text">
            Joni Derdzinski (Joni Clare) is an eclectic professional in the
            Northwoods community. She is a mother of two and holding a strong
            passion for the arts, community and education she is an advisor of
            Terri’s Treehouse’s Elemental Edu., a program dedicated to
            experiential learning. She is passionate about striking a balance
            between all of her endeavors and believes that the constant through
            all is staying present and in relationship to awareness
          </p>
          <Card>
        <iframe
        style={{ border: "0", width: "auto", height: "500px",position: "relative" }}
          src="https://www.youtube.com/embed/6rPAaOxW9vE?si=Ew55UuNH-3fGu_df"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </Card>
          <p className="about-text">
            She is an AmSat certified Alexander Technique teacher and completed
            her 1600 hour certification from the Oregon Center for the Alexander
            Technique in Portland, OR. She is also Certified Level One in
            Framework for Integration, an extension of Alexander Technique which
            explores somatic movement and reintegration of daily movement
            patterns.
          </p>
          <p className="about-text">
            She earned her Bachelor’s of Art is Music from UW-Milwaukee in 2015.
            Her early collegiate training included classical, jazz,
            musical theater, and gospel repertoires while performing in the
            University Choir, Women’s Choir, and Jazz Ensemble. She later
            specialized in extended vocal techniques, experimental
            improvisation, composition, and vocal pedagogy with performances in
            the Contemporary Music Ensemble and Experimental Improvisation
            Ensemble.
            <br />
            <br />
            She has been professionally performing for 15 years and teaching for 10.
            additionally, she received her Vibrational Sound Therapy
            certification in 2023.
          </p>
          <h3 className="about-subtitle">
            Resident Artist at The Wormfarm Institute
          </h3>
          <p className="about-text">
            Clare’s early singing and performances took place in church, home,
            and school. She developed a deep meaning for music by singing with
            her mom and dad in many loving communities that she remains grateful
            for today. She took every opportunity to sing solos and leads in
            show choir, musicals, class choir, and Solo and Ensemble
            competitions. It is very clear that Clare early on carried a strong
            passion and very little fear to sing, dance, and play instruments,
            and these qualities are carried through her performances and
            teaching services today.
          </p>

          <h3 className="about-subtitle">
            Joni Clare with Fable and the World Flat
          </h3>
          <p className="about-text">
            In young adulthood, Clare began to plug her voice into Milwaukee’s
            music scene, back-up singing with local groups like Soup and Fable
            and the World Flat. Her life was filled with studying classical
            pieces and the Alexander Technique by day and performing music by
            Led Zeppelin, Pink Floyd, and Grateful Dead by night. Through this
            dynamic training, she quickly advanced her voice for writing
            original music and cultivated something eclectic that struck a
            balance between her collegiate work, reflective writing, and
            collaborations with other Milwaukee musicians.
          </p>
          <p className="about-text">
            Clare has visited the Wormfarm Institute twice as an
            artist-in-residence and recorded many freely improvised albums, one
            being <em>The Waking Hour</em> (2015). Her most recent album,{" "}
            <em>Memory Strings</em> (2020), was released with her band, The City
            Pines, out in Portland, Oregon. It is clear that Clare’s studies,
            projects, and musical output carry variety, stepping into many types
            of expressions with people of all backgrounds and artistic drives.
          </p>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AboutUs;
